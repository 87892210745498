<template>
  <!-- 我的提单  我的题目 -->
  <div class="">
    <div class="box">
      <el-table v-loading="loading" ref="singleTable" :data="tableData" highlight-current-row @current-change="handleCurrentChange" @row-click="rowClick" style="width: 100%">
        <el-table-column property="name" align="center" label="团队名称" width="220" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="title">{{ scope.row.name }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="创建人">
          <template slot-scope="scope">
            <div class="founder">{{ scope.row.user_realname }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="团队人数">
          <template slot-scope="scope">
            <div class="teamNum">{{ scope.row.member_count }}</div>
          </template>
        </el-table-column>

        <el-table-column property="address" align="center" label="标签">
          <template slot-scope="scope">
            <div class="class">{{scope.row.type_name}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" width="200">
          <template slot-scope="scope">
            <div class="time">{{scope.row.create_time_format}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-link :disabled="!$isPowers('teamDel')" @click.stop="deleteRow(scope.row)" v-if="scope.row.user_id === userInfo.id"><i class="el-icon-delete-solid" style="color: #414141; font-size: 20px"></i></el-link>
            <el-link :disabled="!$isPowers('teamDrop')" @click.stop="clickOutJoin(scope.row.id)" v-if="scope.row.user_id !==  userInfo.id&&scope.row.is_member"><i class="el-icon-switch-button" style="color: #ccc; font-size: 22px"></i></el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page" :style="$attrs.pageStyle || {}" v-if="total > 0">
      <el-pagination background layout="total,sizes,prev, pager, next" :total="total" @current-change="getTeamList" @size-change="onSizeChange" :page-size="pageSize" :current-page="page" >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { teamList, deleteTeam, quit_group } from "@/api/team.js"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      loading:false,
      page: 1,
      pageSize:10,
      total:0,
      tableData: [],
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  mounted () {
    this.getTeamList()
  },
  //方法集合
  methods: {
    // 退出团队
    clickOutJoin (id) {
      if (!localStorage.getItem("user_token")) {
        return this.$store.state.lodinShow = true
      }
      this.$confirm("确定要退出此团队?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          quit_group({ group_id: id })
            .then(({ data }) => {
              console.log(data, "退出成功")
              this.$message.success("退出成功")
              this.getTeamList()
            })
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          })
        })
    },
    // 团队列表
    getTeamList (page) {
      this.page = page || 1;
      this.loading = true
      teamList({ page: this.page, page_size: this.pageSize, my: [1,2] })
        .then(({ data }) => {
          this.tableData = data.data
          this.total = data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSizeChange(pagesize){
      this.pageSize=pagesize
      this.getTeamList();

    },
    // 删除团队
    deleteRow (item) {

      this.$confirm("确定要删除此团队?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          deleteTeam({ group_id: item.id })
            .then(({ data }) => {

              this.$message.success("删除成功")
              this.getTeamList()
            })
        }).catch(() => {
          this.$message.info("已取消删除")
        })

    },
    // 点击团队
    rowClick (row) {
      this.$handleRoute({team_id:row.id},'TeamInfo')
      // this.$router.push({ path: "/team/"+row.id })
    },
    setCurrent (row) {
      this.$refs.singleTable.setCurrentRow(row)
    },
    handleCurrentChange (val) {
      this.currentRow = val
    },
  },
};
</script>
<style  lang='less' scoped>
.box {
  border: 1px solid #979797;
  .img {
    width: 20px;
    height: 20px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .founder,
  .teamNum， .class,
  .time {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #363636;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #01957e;
  }
}
</style>